import axios from 'axios'
import {EncryptConstants} from "@/config/encrypt_constants";
import {CommonConstants} from "@/config/common_constants";
import md5 from 'js-md5';
import EncryptUtils from "../EncryptUtils";
import {Constants} from "../constants";

// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 处理请求配置
    setHttpBaseUrl(config)
    return config;
}, err => {
    // 处理请求错误
    return Promise.reject(err)
})
// axios拦截响应
axios.interceptors.response.use(response => {
    //响应成功的
    return response.data
}, err => {
    //响应错误
    return Promise.reject(err)
})


function getAppName() {
    return "cx"
}

function getAppVersion() {
    return "1.0.1"
}

//获取用户token
// function getUserToken() {
//     if (window.Android && isHostMethod(window.Android, 'getUserToken')) {
//         var userToken = window.Android.getUserToken()
//         console.log(userToken)
//         return JSON.parse(userToken);
//     }
// }

function setHttpBaseUrl(config) {
    var accessToken = ""
    var deviceToken = ""
    try {
        // accessToken = getUserToken().accessToken
        accessToken = localStorage.getItem(Constants.accessToken)
        deviceToken = localStorage.getItem(Constants.userNum)
    } catch (error) {
        console.log("常规错误:请在安卓手机上运行")
        accessToken = ""
        deviceToken = ""
    }
    console.log("我是打印accessToken"+accessToken)
    console.log("我是打印deviceToken"+deviceToken)
    let appName = getAppName();
    let appVersion = getAppVersion();
    var api_root = CommonConstants.BASE_URL;
    config.headers['Content-Type'] = 'application/json';
    config.headers['accessToken'] = accessToken;
    config.headers['appType'] = "h5";
    config.headers['appName'] = appName;
    config.headers['appVersion'] = appVersion;
    config.headers['deviceToken'] = deviceToken;
    config.url = api_root + config.url;
    // window.acceptLanguage
    config.headers['accept-language'] = 'zh-CN';
    //是本机运行环境 并且是 测试服务器 并且 不是安卓 指定userId
    // if (process.env.NODE_ENV === "development" && (api_root.includes("test") || api_root.includes("192")) && !window.Android) {
    // if (api_root.includes("test")) {
    //     config.headers['accessToken'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJJOVBqWkRTbVVnR0tKV1U3IjoiVjR5eWkvOXFKOUlyYkhTNlRuejI1bUs3c1VVajJnUUhPVlNwMXBGb09nNjJNOVplbFdaWllCekc0RWc9IiwiaXNzIjoiektPUlI1b0FDd3RAcml0SzJVSThZNEokKlQjY3pIem0iLCJpYXQiOjE3MDWjN5lhlivCyxJ9XdHY1MjEwMzIsImF1ZCI6Im02bHU3RnVaeVV2MUJYZWUiLCJleHAiOjE3MDkxMTMwMzIsIm5iZiI6MTcwsmtfF36qixUNjUyMTAzMn0.zEoEWkdeIvx3dkU9vEBkpAuspqpGv1G--IG3QO5gdITR6mbQ";
    //     config.headers['deviceToken'] = "10001408";
    // }
}

//处理请求参数
function handleParams(params) {
    ////生成唯一随机字符串(UUID 去中划线-)
    let appNonce = EncryptUtils.uuidMiddle();
    /// 生成当前时间时间戳
    let appTimeStamp = "";
    if (window.Android && isHostMethod(window.Android, 'getServiceTime')) {
        appTimeStamp = Number(window.Android.getServiceTime())
    } else if (window.time) {
        appTimeStamp = window.time
    } else {
        appTimeStamp = Date.parse(new Date())
    }
    // 签名处理
    // -------测试生成appSign-------
    params.appTimeStamp = appTimeStamp;
    params.appNonce = appNonce;
    let paramsAfter = sortASCII(params);
    // 盐值处理
    // var decrypt = CryptoJS.AES.decrypt(
    //     Constants.signSaltKey,
    //     CryptoJS.enc.Utf8.parse(Constants.aseKey),
    //     {
    //         mode: CryptoJS.mode.ECB,
    //         padding: CryptoJS.pad.Pkcs7,
    //     }
    // ).toString(CryptoJS.enc.Utf8);
    paramsAfter.signSercetKey = EncryptConstants.signSaltKey + appNonce;
    let stringAfter = "";
    for (let key in paramsAfter) {
        stringAfter = stringAfter + ("&" + key + "=" + paramsAfter[key])
    }
    stringAfter = stringAfter.substring(1)
    let appSign = (md5(stringAfter)).toLocaleUpperCase();
    params.appTimeStamp = appTimeStamp;
    params.appNonce = appNonce;
    params.appSign = appSign;

    return params;
}

function sortASCII(obj) {
    var arr = new Array();
    var num = 0;
    for (var i in obj) {
        arr[num] = i;
        num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (var j in sortArr) {
        sortObj[sortArr[j]] = obj[sortArr[j]];
    }
    return sortObj;
}

function isHostMethod(object, property) {
    var t = typeof object[property];
    return t == 'function' || (!!(t == 'object' && object[property])) || t == 'unknown';
}

export default {
    get(url, params) {
        return axios.get(url, params);
    },
    post(url, params) {
        let data = handleParams(params)
        return axios.post(url, data);
    },
}
