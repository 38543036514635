import service from './service';

export const Api = {
    // banner配置
    queryPageConfiguration(params) {
        return service.post('/api/pageConfiguration/queryPageConfiguration', params);
    },
    // 查看影响力账户
    queryPowerAccount(params) {
        return service.post('/api/account/queryUserAccountByType', params);
    },
    // 查看影响力流水
    queryPowerRecord(params) {
        return service.post('/api/account/queryAccountFlow', params);
    },
    // 检查登录
    tpLoginPoll(params) {
        return service.post('/api/tpAuth/tpLoginPoll', params);
    },
    // 检查支付
    tpPayPoll(params) {
        return service.post('/api/walletRechargeOrder/tpPayPoll', params);
    },
    queryConfigEntityPure(params) {
        return service.post('/api/exchange/queryConfigEntityPure', params);
    },
    exchangePower(params) {
        return service.post('/api/calculatePowerOrder/exchangePower', params);
    },
    queryOrderList(params) {
        return service.post('/api/calculatePowerOrder/queryOrderList', params);
    },
    queryWalletRechargeConfig(params) {
        return service.post('/api/walletRechargeConfig/queryConfig', params);
    },
    queryWalletRechargeAddOrder(params) {
        return service.post('/api/walletRechargeOrder/addOrder', params);
    },
    queryUserPowerInfo(params) {
        return service.post('/api/calculatePowerOrder/queryUserPowerInfo', params);
    },
    // 创建能量充值订单
    addXValueOrder(params) {
        return service.post('/api/walletRechargeOrder/addOrder', params);
    },
    // 支付能量
    payXValueOrder(params) {
        return service.post('/api/walletRechargeOrder/payOrder', params);
    },
    //查询能量订单
    queryXValueOrderList(params) {
        return service.post('/api/walletRechargeOrder/queryOrderList', params);
    },
    //提取查询配置
    queryWithdrawalChainConfig(params) {
        return service.post('/api/withdrawalChainRecord/queryWithdrawalChainConfig', params);
    },
    // 提币接口
    addWithdrawalChainRecord(params) {
        return service.post('/api/withdrawalChainRecord/addWithdrawalChainRecord', params);
    },
    //查询兑换记录
    queryExchangeOrderList(params) {
        return service.post('/api/exchange/queryOrderList', params);
    },
    queryWithdrawalChainRecord(params) {
        return service.post('/api/withdrawalChainRecord/queryWithdrawalChainRecord', params);
    },
    //查询储存金额列表
    queryStakeAmountList(params) {
        return service.post('/api/stake/queryAmountList', params);
    },
    //立即储存
    queryStakeBuy(params) {
        return service.post('/api/stake/buy', params);
    },

    //用户发放红包
    handOutRedPacket(params) {
        return service.post('/api/redPacket/handOutRedPacket', params);
    },
    //用户管理员发放红包
    handOutRedPacketByAdmin(params) {
        return service.post('/api/redPacket/handOutRedPacketByAdmin', params);
    },
    //领取红包
    userGetRedPacket(params) {
        return service.post('/api/redPacket/userGetRedPacket', params);
    },
    //获取红包状态
    checkRedPacketStatus(params) {
        return service.post('/api/redPacket/checkRedPacketStatus', params);
    },
    //获取分享链接
    listRedPacketConstant(params) {
        return service.post('/api/redPacket/listRedPacketConstant', params);
    },
    //检查红包功能是否开启
    checkRedPacketEnableStatus(params) {
        return service.post('/api/redPacket/checkRedPacketEnableStatus', params);
    },
    //根据总红包标识uuid获取发红包人信息
    getSendUserInfoByUuid(params) {
        return service.post('/api/redPacket/getSendUserInfoByUuid', params);
    },
    //检查红包是否已经领完
    checkRedPacketIsOver(params) {
        return service.post('/api/redPacket/checkRedPacketIsOver', params);
    },
    //红包详情——收入
    listRedPacketDetailById(params) {
        return service.post('/api/redPacket/listRedPacketDetailById', params);
    },
    //红包详情——发出
    listRedPacketById(params) {
        return service.post('/api/redPacket/listRedPacketById', params);
    },
    //用户查看自己的收红包记录
    listGotRedPacket(params) {
        return service.post('/api/redPacket/listGotRedPacket', params);
    },
    //用户查看自己的发红包记录
    listSendRedPacket(params) {
        return service.post('/api/redPacket/listSendRedPacket', params);
    },
    //绑定账号
    userContactAddBind(params) {
        return service.post('/api/userContact/addBind', params);
    },
    //查询绑定情况
    userContactQueryOne(params) {
        return service.post('/api/userContact/queryOne', params);
    },
    //查询储值订单列表
    queryStakeOrderList(params) {
        return service.post('/api/stake/queryOrderList', params);
    },
    //查看用户所收到的红包
    listRedPacketByToUser(params) {
        return service.post('/api/redPacket/listRedPacketByKeycode', params);
    },
    //查询红包配置
    queryRedPacketConfig(params) {
        return service.post('/api/redPacket/queryRedPacketConfig', params);
    },
    //用户领取红包口令
    userGetRedPacketByLoginKeycode(params) {
        return service.post('/api/redPacket/userGetRedPacketByLoginKeycode', params);
    },
    //查看tp支付是否在白名单
    queryIsList(params) {
        return service.post('/api/userBlacklist/queryIsList', params);
    },
    //查询手续费
    queryFee(params) {
        return service.post('/api/withdrawalChainRecord/queryFee', params);
    },
    //模拟领取红包
    userGetRedPacketKeycodeMock(params) {
        return service.post('/api/redPacket/userGetRedPacketKeycodeMock', params);
    },
    //查询兑换配置
    queryExchangeConfig(params) {
        return service.post('/api/exchange/queryExchangeConfig', params);
    },
    //火箭值瓜分-点击瓜分查看瓜分金额
    queryRocketPartitionOrder(params) {
        return service.post('/api/rocketPartitionOrder/queryOrder', params);
    },
    queryRocketPartitionOrderGetReward(params) {
        return service.post('/api/rocketPartitionOrder/getReward', params);
    },
}
